<template>
  <div class="lsg-cabinet">
    <div class="main__header">
      <div class="main__header__item left">
        <div class="btn btn--gray" type="button" @click="() => this.$router.push({ name: 'trade', params: { id: this.$route.params.id } })">
          <i><ArrowLeftIcon/></i>
          <span>Назад</span>
        </div>
      </div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content childs-center">
      <form class="lk lk__checktrade" :key="rerenderCompanyKey">
        <h3>Контрагент</h3>
        <div class="ct__row">
          <div class="bn__col">
            <div
              class="ct__input"
              v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label for="name">Наименование</label>
              <input id="name" type="text" v-model="company.name">
            </div>
            <div
              class="ct__input"
            >
              <label for="fullname">Полное наименование</label>
              <input id="fullname" type="text" v-model="company.fullname" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            </div>
            <div
              class="ct__input"
            >
              <label for="inn">ИНН</label>
              <input id="inn" type="number" v-model="company.inn" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            </div>
            <div
              class="ct__input"
            >
              <label for="kpp">КПП</label>
              <input id="kpp" type="number" v-model="company.kpp" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            </div>
            <div
              class="ct__input"
              v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label for="bank">Банк</label>
              <input id="bank" type="text" v-model="company.bank" readonly>
            </div>
            <div
              class="ct__input"
              v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label>БИК</label>
              <BankInput :default="company.bik" @change="bikChange" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)"/>
            </div>
            <div
              class="ct__input"
              v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label for="rs">Расчетный счет</label>
              <input id="rs" type="number" v-model="company.rs" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            </div>
            <div
              class="ct__input"
              v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label for="adresU">Юридический адрес</label>
              <input id="adresU" type="text" v-model="company.adresU" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            </div>
            <div
              class="ct__input"
              v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label for="adresF">Фактический адрес</label>
              <input id="adresF" type="text" v-model="company.adresF">
            </div>
          </div>
          <div class="bn__col">
            <div
              class="ct__input"
              v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label for="ks">Корреспондентский счет</label>
              <input id="ks" type="number" v-model="company.ks" readonly>
            </div>
            <div
              class="ct__input"
              v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label for="adresU">Юридический адрес</label>
              <input id="adresU" type="text" v-model="company.adresU" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            </div>
            <div
              class="ct__input"
              v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label for="adresF">Фактический адрес</label>
              <input id="adresF" type="text" v-model="company.adresF">
            </div>
            <div
              class="ct__input"
              v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label for="adresD">Почтовый адрес</label>
              <input id="adresD" type="text" v-model="company.adresD">
            </div>
            <div
              class="ct__input"
              v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label>БИК</label>
              <BankInput :default="company.bik" @input="bikChange"/>
            </div>
            <div
              class="ct__input"
              v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
            >
              <label for="rs">Расчетный счет</label>
              <input id="rs" type="number" v-model="company.rs" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            </div>
            <div
              class="ct__input"
            >
              <label for="phone">Телефон</label>
              <input id="phone" type="tel" v-model="company.phone">
            </div>
            <div
              class="ct__input"
            >
              <label for="email">E-mail</label>
              <input id="email" type="email" v-model="company.email">
            </div>
            <div>
              <div class="ct__input">
                <label>Дата предоставления согласия:</label>
                <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="company.agreement"></date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="ct__col">
          <div class="ct__buttons">
            <button class="btn hidden"></button>
            <button class="btn" type="button" @click="saveCompany">Сохранить</button>
          </div>
        </div>
      </form>

      <form class="lk lk__checktrade" :key="rerenderContactKey">
        <h3>Контактное лицо</h3>
        <div class="ct__row">
          <div class="ct__col-3">
            <div class="ct__input">
              <label for="surname">Фамилия:</label>
              <input id="surname" type="text" v-model="contact.surname">
            </div>
          </div>
          <div class="ct__col-3">
            <div class="ct__input">
              <label for="name">Имя:</label>
              <input id="name" type="text" v-model="contact.name">
            </div>
          </div>
          <div class="ct__col-3">
            <div class="ct__input">
              <label for="patron">Отчество:</label>
              <input id="patron" type="text" v-model="contact.patron">
            </div>
          </div>
        </div>
        <div class="ct__row">
          <div class="ct__col-3">
            <div class="ct__input">
              <label>Дата рождения:</label>
              <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="contact.birthday"></date-picker>
            </div>
          </div>
          <div class="ct__col-3">
            <div class="ct__input">
              <label for="post">Должность:</label>
              <input id="post" type="text" v-model="contact.post">
            </div>
          </div>
          <div class="ct__col-3">
            <div class="ct__input">
              <label for="basis">Основание полномочий:</label>
              <input id="basis" type="text" v-model="contact.basis">
            </div>
          </div>
        </div>
        <div class="ct__row">
          <div class="ct__col-2">
            <div class="ct__input">
              <label>Дата предоставления согласия:</label>
              <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="contact.agreement"></date-picker>
            </div>
          </div>
          <div class="ct__col-2">
            <div class="ct__input">
              <label for="birthplace">Место рождения:</label>
              <input id="birthplace" type="text" v-model="contact.birthplace">
            </div>
          </div>
        </div>
        <div class="ct__row">
          <div class="ct__col-2">
            <div class="ct__input">
              <label for="passportId">Номер паспорта:</label>
              <input id="passportId" type="number" v-model="contact.passportId">
            </div>
          </div>
          <div class="ct__col-2">
            <div class="ct__input">
              <label>Дата выдачи паспорта:</label>
              <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="contact.passportDate"></date-picker>
            </div>
          </div>
        </div>
        <div class="ct__row">
          <div class="ct__col">
            <div class="ct__input">
              <label for="passportPlace">Место выдачи паспорта:</label>
              <input id="passportPlace" type="text" v-model="contact.passportPlace">
            </div>
          </div>
        </div>
        <div class="ct__row">
          <div class="ct__col-2">
            <div class="ct__input">
              <label for="passportCode">Код подразделения:</label>
              <input id="passportCode" type="text" v-model="contact.passportCode">
            </div>
          </div>
          <div class="ct__col-2">
            <div class="ct__input">
              <label for="inn">ИНН:</label>
              <input id="inn" type="number" v-model="contact.inn">
            </div>
          </div>
        </div>
        <div class="ct__row">
          <div class="ct__col">
            <div class="ct__input">
              <label for="adresD">Адрес для информирования:</label>
              <input id="adresD" type="text" v-model="contact.adresD">
            </div>
          </div>
        </div>
        <div class="ct__row">
          <div class="ct__col">
            <div class="ct__input">
              <label for="phone">Телефон:</label>
              <input id="phone" type="tel" v-model="contact.phone">
            </div>
          </div>
        </div>
        <div class="ct__row">
          <div class="ct__col">
            <div class="ct__input">
              <label for="email">E-mail:</label>
              <input id="email" type="email" v-model="contact.email">
            </div>
          </div>
        </div>
        <div class="ct__col">
          <div class="ct__buttons">
            <button class="btn hidden"></button>
            <button class="btn" type="button" @click="saveContact">Сохранить</button>
          </div>
        </div>
      </form>

      <div class="lk lk__checktrade" :key="rerenderGuarantorsKey">
        <h3>Поручители</h3>
        <div class="ct__row" v-if="guarantors.length">
          <form class="lk lk__checktrade" v-for="(guarantor, index) in guarantors" :key="guarantor.id + index">
            <h3 class="checktrade__title" :class="{ 'expanded': guarantor.expanded }" @click="guarantor.expanded = !guarantor.expanded">
              {{ guarantor.name }}
              <div class="expand-icon" :class="{ 'expanded': guarantor.expanded }">
                <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 6L8 10L12 6" stroke="#4E555A" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
            </h3>
            <template v-if="guarantor.expanded">
              <div class="ct__row">
                <div class="bn__col">
                  <div
                    class="ct__input"
                    v-if="['approved', 'dl'].includes($store.state.Lead.status)"
                  >
                    <label for="name">Наименование</label>
                    <input id="name" type="text" v-model="guarantor.name">
                  </div>
                  <div
                    class="ct__input"
                  >
                    <label for="fullname">Полное наименование</label>
                    <input id="fullname" type="text" v-model="guarantor.fullname" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
                  </div>
                  <div
                    class="ct__input"
                  >
                    <label for="inn">ИНН</label>
                    <input id="inn" type="number" v-model="guarantor.inn" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
                  </div>
                  <div
                    class="ct__input"
                  >
                    <label for="kpp">КПП</label>
                    <input id="kpp" type="number" v-model="guarantor.kpp" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
                  </div>
                  <div
                    class="ct__input"
                    v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label for="bank">Банк</label>
                    <input id="bank" type="text" v-model="guarantor.bank" readonly>
                  </div>
                  <div
                    class="ct__input"
                    v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label>БИК</label>
                    <BankInput :default="guarantor.bik" @input="guarantorBikChange($event, index)" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)"/>
                  </div>
                  <div
                    class="ct__input"
                    v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label for="rs">Расчетный счет</label>
                    <input id="rs" type="number" v-model="guarantor.rs" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
                  </div>
                  <div
                    class="ct__input"
                    v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label for="adresU">Юридический адрес</label>
                    <input id="adresU" type="text" v-model="guarantor.adresU" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
                  </div>
                  <div
                    class="ct__input"
                    v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label for="adresF">Фактический адрес</label>
                    <input id="adresF" type="text" v-model="guarantor.adresF">
                  </div>
                </div>
                <div class="bn__col">
                  <div
                    class="ct__input"
                    v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label for="ks">Корреспондентский счет</label>
                    <input id="ks" type="number" v-model="guarantor.ks" readonly>
                  </div>
                  <div
                    class="ct__input"
                    v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label for="adresU">Юридический адрес</label>
                    <input id="adresU" type="text" v-model="guarantor.adresU" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
                  </div>
                  <div
                    class="ct__input"
                    v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label for="adresF">Фактический адрес</label>
                    <input id="adresF" type="text" v-model="guarantor.adresF">
                  </div>
                  <div
                    class="ct__input"
                    v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label for="adresD">Почтовый адрес</label>
                    <input id="adresD" type="text" v-model="guarantor.adresD">
                  </div>
                  <div
                    class="ct__input"
                    v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label>БИК</label>
                    <BankInput :default="guarantor.bik" @input="guarantorBikChange($event, index)"/>
                  </div>
                  <div
                    class="ct__input"
                    v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
                  >
                    <label for="rs">Расчетный счет</label>
                    <input id="rs" type="number" v-model="guarantor.rs" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
                  </div>
                  <div
                    class="ct__input"
                  >
                    <label for="phone">Телефон</label>
                    <input id="phone" type="tel" v-model="guarantor.phone">
                  </div>
                  <div
                    class="ct__input"
                  >
                    <label for="email">E-mail</label>
                    <input id="email" type="email" v-model="guarantor.email">
                  </div>
                  <div>
                    <div class="ct__input">
                      <label>Дата предоставления согласия:</label>
                      <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="guarantor.agreement"></date-picker>
                    </div>
                  </div>
                </div>
                <div class="ct__col">
                  <div class="ct__buttons">
                    <button class="btn btn--red" type="button" @click="unpinGuarantor(guarantor.inn)">Открепить</button>
                    <button class="btn" type="button" @click="saveGuarantor(guarantor)">Сохранить</button>
                  </div>
                </div>
              </div>
              <h3>Контактное лицо</h3>
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="surname">Фамилия:</label>
                    <input id="surname" type="text" v-model="guarantor.contact.surname">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="name">Имя:</label>
                    <input id="name" type="text" v-model="guarantor.contact.name">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="patron">Отчество:</label>
                    <input id="patron" type="text" v-model="guarantor.contact.patron">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label>Дата рождения:</label>
                    <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="guarantor.contact.birthday"></date-picker>
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="post">Должность:</label>
                    <input id="post" type="text" v-model="guarantor.contact.post">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="basis">Основание полномочий:</label>
                    <input id="basis" type="text" v-model="guarantor.contact.basis">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-2">
                  <div class="ct__input">
                    <label>Дата предоставления согласия:</label>
                    <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="guarantor.contact.agreement"></date-picker>
                  </div>
                </div>
                <div class="ct__col-2">
                  <div class="ct__input">
                    <label for="birthplace">Место рождения:</label>
                    <input id="birthplace" type="text" v-model="guarantor.contact.birthplace">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-2">
                  <div class="ct__input">
                    <label for="passportId">Номер паспорта:</label>
                    <input id="passportId" type="number" v-model="guarantor.contact.passportId">
                  </div>
                </div>
                <div class="ct__col-2">
                  <div class="ct__input">
                    <label>Дата выдачи паспорта:</label>
                    <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="guarantor.contact.passportDate"></date-picker>
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col">
                  <div class="ct__input">
                    <label for="passportPlace">Место выдачи паспорта:</label>
                    <input id="passportPlace" type="text" v-model="guarantor.contact.passportPlace">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-2">
                  <div class="ct__input">
                    <label for="passportCode">Код подразделения:</label>
                    <input id="passportCode" type="text" v-model="guarantor.contact.passportCode">
                  </div>
                </div>
                <div class="ct__col-2">
                  <div class="ct__input">
                    <label for="inn">ИНН:</label>
                    <input id="inn" type="number" v-model="guarantor.contact.inn">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col">
                  <div class="ct__input">
                    <label for="adresD">Адрес для информирования:</label>
                    <input id="adresD" type="text" v-model="guarantor.contact.adresD">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col">
                  <div class="ct__input">
                    <label for="phone">Телефон:</label>
                    <input id="phone" type="tel" v-model="guarantor.contact.phone">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col">
                  <div class="ct__input">
                    <label for="email">E-mail:</label>
                    <input id="email" type="email" v-model="guarantor.contact.email">
                  </div>
                </div>
              </div>
              <div class="ct__col">
                <div class="ct__buttons">
                  <button class="btn hidden"></button>
                  <button class="btn" type="button" @click="saveGuarantorContact(guarantor.contact)">Сохранить</button>
                </div>
              </div>
            </template>
          </form>
        </div>
        <div class="ct__row">
          <div class="ct__col">
            <div class="ct__buttons">
              <button v-if="!toggleNewGuarantor" type="button" class="btn" @click="toggleNewGuarantor = !toggleNewGuarantor">
                <i><ArrowLeftIcon/></i>
                <span>новый поручитель</span>
              </button>
            </div>
            <div class="ct__row">
              <div class="ct__col-3">
                <div class="ct__input">
                  <input v-if="toggleNewGuarantor" type="text" v-model="newGuarantorInn" placeholder="Введите ИНН">
                </div>
                <div class="ct__input">
                  <button v-if="toggleNewGuarantor" type="button" class="btn" @click="addGuarantor">Сохранить</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form class="lk lk__checktrade" :key="rerenderCalcKey">
        <h3>Калькуляторы</h3>
        <div class="ct__row" v-for="calculator in calcs" :key="calculator.id">
          <div class="ct__col-4">
            <div class="ct__input">
              <button type="button" class="btn width-100 btn__light_orange" @click.prevent="showCalcModal(null, 'update', calculator.name, calculator, calculator.id)">{{ calculator.name }}</button>
            </div>
          </div>
          <div class="ct__col-4">
            <div class="ct__input">
              <div class="calc-info width-100">Стоимость: {{ prettifyPrice(calculator.price) }}</div>
            </div>
          </div>
          <div class="ct__col-4">
            <div class="ct__input">
              <div class="calc-info width-100">Аванс: {{ prettifyPrice(calculator.prepaid) }}</div>
            </div>
          </div>
          <div class="ct__col-4">
            <div class="ct__input">
              <div class="calc-info width-100">Срок: {{ calculator.duration }} м.</div>
            </div>
          </div>
        </div>
        <div class="ct__row">
          <div class="ct__col">
            <div class="ct__buttons">
              <button type="button" class="btn" @click.prevent="showCalcModal($store.state.Lead.id, 'add', 'Новый калькулятор', null)">
                <i><PlusIco/></i>
                <span>новый калькулятор</span>
              </button>
            </div>
          </div>
        </div>
      </form>

      <form class="lk lk__checktrade" :key="rerenderDocumentsKey">
        <h3>Документы</h3>
        <div class="ct__row">
          <div v-for="(item, i) in files.filter(elem => elem.files)" class="lk lk__checktrade" :key="item.title">
            <div class="ct__doc" style="width: 100%" v-if="item.files && item.files.length" :key="i">
              <h3 class="checktrade__title" :class="{ 'expanded': item.expanded }" @click="item.expanded = !item.expanded">
                {{ item.title }}
                <div class="expand-icon" :class="{ 'expanded': item.expanded }">
                  <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6L8 10L12 6" stroke="#4E555A" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </h3>
              <ul class="ct__doc__list" v-if="item.expanded">
                <li v-for="(file, index) in item.files.filter(file => file.path)" :key="index" class="ct__doc__list__item">
                  <div class="ct__doc__type__container">
                    <div class="ct__doc__link">
                      <a target="_blank" :href="'https://' + file.path" v-if="file.path" download>{{ file.name }}.{{ file.ext }}</a>
                    </div>
                    <span class="ct__doc__type" v-if="file.date">{{ dateTransform(file.date) }}</span>
                    <span v-else class="ct__doc__type">{{ file.type }}</span>
                  </div>
                  <div v-if="file.date" class="ct__doc__type__container ct__doc__type__container--end">
                    <span class="ct__doc__type">{{ file.type }}</span>
                  </div>
                </li>
                <li v-for="(nfile, index) in newFiles[i].files" :key="index + 100" class="ct__doc__list__item ct__doc__list__item--old" style="z-index: 99999">
                  <div>
                    <a class="ct__doc__link" href="javascript:void(0)">{{ nfile.file.name }}</a>
                    <v-select appendToBody :options="item.filetypes.map(file => ({label: file.type, code: file}))" v-model="nfile.info">
                      <template slot="no-options">Нет результатов</template>
                      <template #open-indicator="{ attributes }">
                        <div v-bind="attributes">
                          <DownIcon/>
                        </div>
                      </template>
                    </v-select>
                  </div>
                  <div @click="removeDoc(i, index)">X</div>
                </li>
              </ul>
              <div class="ct__doc__drop" v-if="item.expanded">
                <vue-dropzone
                  :id="'dropzone' + i"
                  @vdropzone-files-added="filesPicked($event, i)"
                  :includeStyling="false"
                  useCustomSlot
                  :options="dropzoneOptions">
                  <div class="lk__dz">
                    <i>
                      <PaperClip/>
                    </i>
                    <span>Выберите или перетащите сюда файлы</span>
                  </div>
                </vue-dropzone>
              </div>
            </div>
          </div>
          <div v-for="(item, i) in guarantorFiles.filter(elem => elem.files)" class="lk lk__checktrade" :key="item.title + i">
            <div class="ct__doc" style="width: 100%" v-if="item.files && item.files.length" :key="i">
              <h3 class="checktrade__title" :class="{ 'expanded': item.expanded }" @click="item.expanded = !item.expanded">
                {{ item.title }}
                <div class="expand-icon" :class="{ 'expanded': item.expanded }">
                  <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6L8 10L12 6" stroke="#4E555A" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </h3>
              <ul class="ct__doc__list" v-if="item.expanded">
                <li v-for="(file, index) in item.files.filter(file => file.path)" :key="index" class="ct__doc__list__item">
                  <div class="ct__doc__type__container">
                    <div class="ct__doc__link">
                      <a target="_blank" :href="'https://' + file.path" v-if="file.path" download>{{ file.name }}.{{ file.ext }}</a>
                    </div>
                    <span class="ct__doc__type" v-if="file.date">{{ dateTransform(file.date) }}</span>
                    <span v-else class="ct__doc__type">{{ file.type }}</span>
                  </div>
                  <div v-if="file.date" class="ct__doc__type__container ct__doc__type__container--end">
                    <span class="ct__doc__type">{{ file.type }}</span>
                  </div>
                </li>
                <li v-for="(nfile, index) in newFiles[i].files" :key="index + 100" class="ct__doc__list__item ct__doc__list__item--old" style="z-index: 99999">
                  <div>
                    <a class="ct__doc__link" href="javascript:void(0)">{{ nfile.file.name }}</a>
                    <v-select appendToBody :options="item.filetypes.map(file => ({label: file.type, code: file}))" v-model="nfile.info">
                      <template slot="no-options">Нет результатов</template>
                      <template #open-indicator="{ attributes }">
                        <div v-bind="attributes">
                          <DownIcon/>
                        </div>
                      </template>
                    </v-select>
                  </div>
                  <div @click="removeDoc(i, index)">X</div>
                </li>
              </ul>
              <div class="ct__doc__drop" v-if="item.expanded">
                <vue-dropzone
                  :id="'dropzone' + i"
                  @vdropzone-files-added="filesPicked($event, i)"
                  :includeStyling="false"
                  useCustomSlot
                  :options="dropzoneOptions">
                  <div class="lk__dz">
                    <i>
                      <PaperClip/>
                    </i>
                    <span>Выберите или перетащите сюда файлы</span>
                  </div>
                </vue-dropzone>
              </div>
            </div>
          </div>
          <div class="ct__buttons" style="width: 100%">
            <button class="btn hidden"></button>
            <button class="btn" type="button" @click="saveDocs" :disabled="loading">{{ loading ? 'Подождите...' : 'Сохранить' }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import UPDATE_PROJECT from '@/graphql/mutations/UpdateProject.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import BankInput from '@/components/ui/GraphInputs/BankInput'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import vue2Dropzone from 'vue2-dropzone'
import PaperClip from '@/components/svg/PaperClip'
import DownIcon from '@/components/svg/DownIcon'
import PlusIco from '@/components/svg/plus'
import axios from 'axios'
import ADD_GUARANTOR from '@/graphql/mutations/AddGuarantor.gql'
import UNPIN_GUARANTOR from '@/graphql/mutations/UnpinGuarantor.gql'
import CHECK_PROJECT from '@/graphql/queries/CheckProject.gql'
import ArrowLeftIcon from '@/components/svg/arrow_left'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'CheckTrade',
  components: {
    BankInput,
    DatePicker,
    DownIcon,
    PaperClip,
    vueDropzone: vue2Dropzone,
    PlusIco,
    ArrowLeftIcon
  },
  data () {
    return {
      title: 'Проверка реквизитов',
      loaded: false,
      company: null,
      contact: {},
      guarantors: [],
      calc: [],
      files: [],
      guarantorFiles: [],
      newFiles: [],
      loading: false,
      toggleNewGuarantor: false,
      newGuarantorInn: '',
      rerenderCompanyKey: 1000,
      rerenderContactKey: 2000,
      rerenderGuarantorsKey: 3000,
      rerenderCalcsKey: 4000,
      rerenderDocumentsKey: 5000,
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('YYYY-MM-DD') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'YYYY-MM-DD').toDate() : null
        }
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        acceptedFiles: '*'
      }
    }
  },
  created () {
    this.loadCheckProjectData()
  },
  watch: {
    isSwitchCalcModal: function (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.loadCheckProjectData()
      }
    }
  },
  computed: {
    rerenderCalcKey () {
      return this.$store.state.data.trades.rerenderCalcKey
    },
    isSwitchCalcModal () {
      return this.$store.state.data.trades.fullCalcModal
    }
  },
  methods: {
    prettifyPrice (price) {
      return `${Number(price).toLocaleString('ru', {
        maximumFractionDigits: 2
      })} ₽`
    },
    unpinGuarantor (inn) {
      this.$apollo
        .mutate({
          mutation: UNPIN_GUARANTOR,
          variables: {
            project_id: this.$store.state.Lead.project_id,
            inn
          },
          update: (_, { data }) => {
            if (data) {
              this.$notify({
                group: 'lsg-notify',
                text: 'Поручитель успешно откреплён'
              })
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: 'Не удалось открепить поручителя'
            })
          })
        })
    },
    addGuarantor () {
      this.toggleNewGuarantor = false

      if (this.newGuarantorInn === this.company.inn) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Нельзя в качестве поручителя добавить текущего контрагента'
        })
        return
      }

      if (this.guarantors.map(elem => elem.inn).includes(this.newGuarantorInn).length) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Эта компания уже используется в качестве поручителя'
        })
        return
      }

      this.$apollo
        .mutate({
          mutation: ADD_GUARANTOR,
          variables: {
            project_id: this.$store.state.Lead.project_id,
            inn: this.newGuarantorInn
          },
          update: (_, { data }) => {
            if (data) {
              this.$notify({
                group: 'lsg-notify',
                text: 'Поручитель успешно добавлен'
              })
            }
          }
        })
        .catch((error) => {
          this.disableGuarantorButton = false
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    },
    fetchGuarantorData () {
      if (this.$store.state.Lead && this.$store.state.Lead.guarantors && this.$store.state.Lead.guarantors.length) {
        this.$store.state.Lead.guarantors.forEach(guarantor => {
          this.$store.dispatch('guarantor', {
            context: this,
            data: {
              id: this.$store.state.checkProject.Project.id,
              inn: guarantor.inn
            }
          })
            .then(async () => {
              let guarantor = await this.$store.state.checkProject.Company
              this.guarantors = []
              this.guarantors.push({
                ...guarantor,
                expanded: false
              })
              this.guarantorFiles = []
              this.guarantorFiles.push({
                expanded: false,
                title: `Поручитель. ${guarantor.name}`,
                files: guarantor.files,
                filetypes: guarantor.filetypes
              })
              this.rerenderGuarantorsKey++
            })
        })
      }
    },
    removeDoc (indexFile, indexNewFile) {
      this.newFiles[indexFile].files.splice(indexNewFile, 1)
    },
    validate () {
      this.loading = true
      this.newFiles.forEach(object => {
        object.files.forEach(file => {
          if (!file.info) {
            alert('Не все поля заполнены')
            this.loading = false
            throw new Error('Не все поля заполнены')
          }
        })
      })
    },
    saveDocs () {
      this.validate()
      let formData = new FormData()
      let index = 0
      this.newFiles.forEach(newFile => {
        newFile.files.forEach((item) => {
          formData.append(`file[${index}][file]`, item.file)
          Object.keys(item.info.code).forEach(key => {
            formData.append(`file[${index}][${key}]`, item.info.code[key])
          })
          formData.append(`file[${index}][user]`, this.$store.state.me.name)
          index += 1
        })
      })
      const BASE_URL = process.env.VUE_APP_HTTP
      const url = `${BASE_URL}/upload`
      axios.post(url, formData)
        .then(res => {
          if (res.status === 200) {
            this.$notify({
              group: 'lsg-notify',
              duration: 10000,
              text: 'Данные сохранены'
            })
          }
          this.loadCheckProjectData()
        })
        .finally(() => {
          this.loading = false
        })
    },
    async updateProject (data) {
      await this.$apollo.mutate({
        mutation: UPDATE_PROJECT,
        variables: data
      }).then(() => {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Данные сохранены'
        })
      })
        .catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    },
    async saveGuarantor (guarantor) {
      await this.updateProject({
        objectType: 'company',
        objectId: guarantor.id,
        input: guarantor
      })
        .then(async () => {
          await this.fetchGuarantorData()
        })
    },
    async saveGuarantorContact (contact) {
      await this.updateProject({
        objectType: 'contact',
        objectId: contact.id,
        input: contact
      })
        .then(async () => {
          await this.fetchGuarantorData()
        })
    },
    async saveCompany () {
      await this.updateProject({
        objectType: 'company',
        objectId: this.company.id,
        input: this.company
      })
        .then(async () => {
          await this.fetchCheckProjectCompany()
        })
    },
    async saveContact () {
      const input = this.contact
      await this.updateProject({
        objectType: 'contact',
        objectId: input.id,
        input: input
      })
        .then(async () => {
          await this.fetchCheckProjectContact()
        })
    },
    async fetchCheckProjectCompany () {
      try {
        const result = await this.$apollo.mutate({
          mutation: CHECK_PROJECT,
          variables: {
            id: this.$route.params.id
          }
        })
        this.company = result.data.checkProject.Company
        this.$nextTick(() => {
          this.rerenderCompanyKey++
        })
      } catch (error) {
        this.$notify({
          group: 'lsg-notify',
          text: error
        })
      }
    },
    async fetchCheckProjectCalc () {
      try {
        await this.$apollo.mutate({
          mutation: CHECK_PROJECT,
          variables: {
            id: this.$route.params.id
          }
        })
          .then((res) => {
            this.calc = res.data.checkProject.Calc
            this.$store.commit('incrementCalcRerenderKey')
          })
      } catch (error) {
        this.$notify({
          group: 'lsg-notify',
          text: error
        })
      }
    },
    async fetchCheckProjectContact () {
      try {
        const result = await this.$apollo.mutate({
          mutation: CHECK_PROJECT,
          variables: {
            id: this.$route.params.id
          }
        })
        this.contact = result.data.checkProject.Company.contact
        this.$nextTick(() => {
          this.rerenderContactKey++
        })
      } catch (error) {
        this.$notify({
          group: 'lsg-notify',
          text: error
        })
      }
    },
    loadCheckProjectData () {
      this.$store.dispatch('agentsList', this)
      this.$store.dispatch('providersList', this)
      this.$store.dispatch('fetchLead', {
        context: this,
        data: {
          id: this.$route.params.id
        }
      })
      this.$store.dispatch('checkProject', {
        context: this,
        data: {
          id: this.$route.params.id
        }
      }).then(() => {
        this.company = this.$store.state.checkProject.Company
        this.files = []
        this.files.push({
          expanded: false,
          title: 'Лизингополучатель',
          files: this.company.files,
          filetypes: this.company.filetypes
        })
        if (typeof this.$store.state.checkProject.Company.contact === 'object') {
          this.contact = this.$store.state.checkProject.Company.contact
        }
        this.calcs = this.$store.state.checkProject.Calc
        this.$store.state.checkProject.Calc.forEach(calc => {
          const title = calc.provider ? `Поставщик. ${calc.provider.name}` : `Калькулятор. ${calc.name}`
          this.files.push({
            expanded: false,
            title: title,
            files: calc.provider.files,
            filetypes: calc.provider.filetypes
          })
        })
        this.files.push({
          expanded: false,
          title: 'Документы по сделке',
          files: this.$store.state.checkProject.Project.files,
          filetypes: this.$store.state.checkProject.Project.filetypes
        })
        this.newFiles = this.files.map(file => ({
          title: file.title,
          files: []
        }))
        this.fetchGuarantorData()
        this.loaded = true
      })
    },
    bikChange (e) {
      this.company.bank = e.name
      this.company.bik = e.bik
    },
    guarantorBikChange (e, guarantorIndex) {
      this.guarantors[guarantorIndex].bank = e.name
      this.guarantors[guarantorIndex].bik = e.bik
    },
    filesPicked (e, i) {
      this.newFiles[i].files = [...this.newFiles[i].files, ...Array.from(e).map(file => ({ file: file, info: null }))]
    },
    dateTransform (date) {
      if (!date) return ''

      return moment(date).format('DD.MM.YYYY HH:mm')
    },
    showCalcModal (leadId, type, header, data, calcId) {
      this.$store.state.data.trades.fullCalcModal = !this.$store.state.data.trades
        .fullCalcModal
      this.$store.state.data.trades.fullCalcDataModalProps.leadId = leadId
      this.$store.state.data.trades.fullCalcDataModalProps.calcId = calcId
      this.$store.state.data.trades.fullCalcDataModalProps.type = type
      this.$store.state.data.trades.fullCalcDataModalProps.header = header
      this.$store.state.data.trades.fullCalcDataModalProps.data = data
    }
  }
}
</script>

<style lang="stylus" scoped>
.ct {
  display flex
  flex-direction column

  &__doc {
    display flex
    flex-direction column
    margin-bottom 30px

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: $black;
      margin: 0 0 15px;
    }

    &__link {
      display inline-flex
      align-items center

      div {
        margin-right 10px

        svg {
          width 10px
          height 10px
        }
      }

      a {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: $orange;
      }
    }

    &__type {
      display flex
      background: rgba($darkgray, 0.3);
      border-radius: 5px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: $black;
      padding 6px 13px;
      min-width 100px

      margin-top 10px

      +below(768px) {
        width 100%
      }

      &__container {
        display flex
        flex-wrap wrap
        justify-content space-between
        flex-direction row
        width 100%

        &--end {
          justify-content flex-end
        }
      }
    }

    .v-select {
      width auto
    }

    &__drop {
      width 100%
      display flex
      align-items center
      margin-top 20px

      .dz-preview {
        display none
      }

      .dz-clickable {
        width 100%
      }

      .lk__dz {
        width 100%
        height 64px !important
      }
    }

    &__list {
      display flex
      flex-direction column
      padding 0
      background: $white;
      border: 1px solid $pinky;
      box-sizing: border-box;
      border-radius: 10px;
      width 100%

      &__item {
        padding 15px 20px
        display flex
        align-items flex-start
        flex-wrap wrap
        justify-content space-between
        flex-direction row

        // +below(768px) {
        //   flex-direction column
        //   align-items flex-start
        //   justify-content flex-start
        // }

        &:hover {
          background rgba($darkgray, 0.3);
        }

        &:not(:last-child) {
          border-bottom 1px solid $pinky
        }

        input {
          visibility hidden
          opacity 0
          width 0 !important
          height 0 !important
        }

        &.ct__doc__nl {
          > span {
            color $red
          }

          > label
          > a {
            color $red
          }
        }
      }
    }

    &s {
      display flex
      flex-direction column
      max-width 640px
      width 100%
    }
  }

  &__header {
    display flex
    align-items center
    justify-content space-between
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    padding 20px 32px 20px 40px
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom 10px
    position relative
    +below(960px) {
      flex-direction column
    }

    &:after {
      content ""
      width calc(100% - 80px)
      absolute top left 40px bottom
      height 2px
      background $darkgray
      align-self center
      margin auto
      +below(960px) {
        display none
      }
    }

    &__item {
      display inline-flex
      align-items center
      background $white
      z-index 1
      padding 0 20px
      +below(960px) {
        width 100%
        padding 0
        margin 10px 0
      }

      &:first-child {
        padding-left 0
      }

      &:last-child {
        padding-right 0
      }

      i {
        width 50px
        height 50px
        display flex
        align-items center
        justify-content center
        border 1px solid $orange
        border-radius 100%
        background $orange

        svg {
          width 24px
          height 24px

          path[stroke] {
            stroke $white
          }

          path[fill] {
            fill $white
          }
        }
      }

      span {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color $orange
        margin-left 10px
      }

      &--active {
        & ~ .ct__header__item {
          i {
            background $white
            border-color $darkgray

            svg {
              path[stroke] {
                stroke $c7A
              }

              path[fill] {
                fill $c7A
              }
            }
          }

          span {
            color rgba($black, 0.3)
          }
        }
      }
    }
  }

  &__body {
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    box-shadow: 0 10px 30px rgba($dark, 0.17);
    border-radius: 10px;
    display flex
    flex-direction column
    padding 30px 40px 40px

    &__header {
      font-family font_regular
      font-weight 500
      font-size: 24px;
      line-height: 29px;
      color: $black;
      margin-bottom 28px
    }
  }

  &__form {
    display flex
    align-items center
  }

  &__col {
    display flex
    flex-direction column
    width 100%
  }

  &__col-2 {
    display flex
    flex-direction column
    width calc(50% - (10px * 2))
    +below(768px) {
      width 100%
    }
  }

  &__col-3 {
    display flex
    flex-direction column
    width calc(33.333333% - (10px * 2))
    +below(768px) {
      width 100%
    }
  }

  .checkbox-component {
    margin-left 10px
    margin-top 15px

    label {
      display flex
      align-items center

      span:not(.input-box) {
        margin-left 10px
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        color: $black;
      }
    }
  }

  &__bottom {
    display flex
    flex-direction column
    width 100%

    .ct__message {
      margin 0 0 15px
      text-align left
    }
  }

  &__buttons {
    display flex
    justify-content space-between

    .btn {
      &:first-child {
        background $dark
        border-color $dark
      }

      &.hidden {
        cursor default
        opacity 0
      }

      i {
        width 16px
        height 16px
        margin-left 10px

        svg {
          width 100%
          height 100%

          path[stroke] {
            stroke $white !important
          }

          path[fill] {
            fill $white !important
          }
        }
      }
    }
  }

  &__message {
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    margin 5px -10px
    color: $red;
    text-align right
    width 100%
  }

  &__row {
    display flex
    align-items flex-end
    justify-content space-between
    width calc(100% + 20px)
    margin 0 -10px
    flex-wrap: wrap;

    > * {
      margin-left 10px !important
      margin-right 10px !important
    }

    .btn {
      button($orange, $white)

      i {
        width 16px
        height 16px
        margin-left 10px
        color $white

        svg {
          width 100%
          height 100%

          path[stroke] {
            stroke #fff
          }

          path[fill] {
            fill #fff
          }
        }
      }
    }
  }

  &__input {
    display flex
    flex-direction column
    align-items flex-start
    width 100%
    margin 10px 0

    &.required {
      > input
      select
      .vs__dropdown-toggle
      .graph-search-input input
      .mx-datepicker input
      .field input
      textarea {
        border-color $red !important
      }
    }

    > label {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $c7A;
      margin-bottom 10px
    }

    .graph-search-input
    .field
    .mx-datepicker
    .v-select {
      width 100%
    }

    > input
    select
    .vs__dropdown-toggle
    .graph-search-input input
    .mx-datepicker input
    .field input
    textarea {
      resize vertical
      width 100%
      background: $white !important;
      border: 1px solid $c7A !important;
      box-sizing: border-box !important;
      border-radius: 10px !important;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $black;
    }

    &s {
      max-width 640px
      width 100%
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      margin -10px 0
      +below(1120px) {
        max-width 100%
      }
    }
  }

  &__back {
    display flex
    align-items center
    justify-content center
    width: 100%;
    +below(1120px) {
      display none
    }

    img {
      width auto
    }
  }
}

.lk__checktrade {
  margin-bottom 20px
  padding 25px
  width 100%
  max-width 1100px

  background $white
  border 1px solid $pinky
  box-shadow 0 7px 20px rgba(0,0,0,0.06)
  border-radius 10px
}

.childs-center {
  display flex
  align-items center
  flex-direction column
}

.checktrade__title {
  display flex
  flex-direction row
  flex-wrap nowrap
  justify-content space-between
  width 100%
  cursor pointer
  margin-bottom 0px

  .expanded {
    margin-bottom 30px
  }
}

.expand-icon {
  height 30px
  width 30px
  border-radius 50%
  z-index 3
  cursor pointer
  display flex
  justify-content center
  align-items center

  .expanded {
    > svg {
      transform-origin: center center
      transform rotate(180deg)
    }
  }
}

.btn--disable {
  background $c979797
  color $white
  border-color $c979797

  &:hover {
    background $c979797
    cursor auto
  }

  &:active {
    transform none
  }
}

.calc-info {
  display flex
  justify-content center
  align-items center
  border 1px solid $black
  border-radius 5px
  min-height 40px
  padding 5px 30px
  font-size 1em
}

.width-100 {
  width 100%
}

.ct__row .btn svg path[stroke] {
  stroke $white !important
}

</style>
