<template>
  <div class="field">
    <input class="field__input" type="text" :readonly="readonly" v-model="inputText" @input="fetchBanks"/>
    <div class="field__list" v-if="banks.length">
      <div class="field__list__item" @click="selectBank(bank)" v-for="(bank, i) in banks" :key="i">{{ bank.name }}</div>
    </div>
  </div>
</template>

<script>
import SEARCH_BIK from '@/graphql/queries/SearchBik.gql'

export default {
  name: 'BankInput',
  props: {
    default: {
      type: String,
      required: false,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputText: null,
      timeout: null,
      banks: []
    }
  },
  created () {
    this.inputText = this.default
  },
  methods: {
    fetchBanks (e) {
      if (this.inputText.length >= 5) {
        this.banks = []
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$apollo.mutate({
            mutation: SEARCH_BIK,
            variables: {
              bik: this.inputText ? this.inputText : ''
            }
          }).then(({ data }) => {
            this.banks = data.bankList.slice(0, 5)
          })
        }, 500)
      }
    },
    selectBank (bank) {
      this.inputText = bank.bik
      clearTimeout(this.timeout)
      this.banks = []
      this.$emit('input', bank)
    }
  }
}
</script>

<style lang="stylus">
.field {
  position relative

  &__list {
    absolute left bottom
    transform translateY(100%)
    z-index 999
    background $white
    border-radius 0 0 5px 5px
    box-shadow: 0 10px 30px rgba($darker, 0.17);
    user-select none

    &__item {
      padding 10px
      font-size 12px
      line-height 16px
      font-weight bold
      cursor pointer
      color $dark
      transition .3s

      &:hover {
        background $orange
        color $white
      }
    }
  }
}
</style>
